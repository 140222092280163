import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeHardnessService } from 'services/rope-hardness-service';
import { RopePlacementService } from 'services/rope-placement-service';
import { AnalysisRopeService } from 'services/analysis-rope-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';
import { MaterialService } from 'services/material-service';
import { RopeDirection } from 'models/RopeDirection';

@containerless
@autoinject
export class AnalysisRopeFormInlineEdit {
  @bindable private analysisRope: Models.AnalysisRope;
  @bindable private minimumBreakingStrength;

  private directionHorizontal = false;
  private currentRopeType: Models.AnalysisRope;
  private actualBreakingStrengthMissing = false;

  constructor(
    private analysisRopeService: AnalysisRopeService,
    private ropeTypeService: RopeTypeService,
    private ropePlacementService: RopePlacementService,
    private materialService: MaterialService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private ropeHardnessService: RopeHardnessService,
    private ropeDimensionService: RopeDimensionService
  ) {}

  private attached() {
    this.checkRopeDirection();
  }

  private updateRope() {
    this.analysisRope.RopePlacement = null;
    this.analysisRope.RopeDimension = null;
    this.analysisRope.RopeType = null;
    this.analysisRope.Material = null;

    this.analysisRopeService
      .put(this.analysisRope, this.analysisRope.Id)
      .then(() => {
        this.eventAggregator.publish('ropeListReset', 1);
        this.eventAggregator.publish('ropeFormEditClose', 1);
        this.toastService.showSuccess('rope.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private checkRopeDirection() {
    if (this.analysisRope.RopePlacement == null) {
      return;
    }

    if (this.analysisRope.RopePlacement.Direction == RopeDirection.Horizontal) {
      this.directionHorizontal = true;
    } else {
      this.directionHorizontal = false;
      this.analysisRope.Depth = null;
    }
  }

  private deleteRope() {
    this.analysisRopeService
      .delete(this.analysisRope.Id)
      .then(() => {
        this.eventAggregator.publish('ropeFormEditClose', 1);
        this.eventAggregator.publish('ropeListReset', 1);
        this.toastService.showSuccess('rope.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('ropeFormEditClose', 1);
    this.eventAggregator.publish('ropeListReset', 1);
  }

  private async changeRopeType(event) {
    this.analysisRope.RopeTypeId = event.detail.value;

    const data = await this.ropeTypeService.get(
      this.analysisRope.RopeTypeId + '?$expand=RopeDimension,NavisionProduct'
    );

    if (data.NavisionProductId == null) {
      this.analysisRope.MinBreakingStrength = null;
    } else {
      this.analysisRope.MinBreakingStrength = data.NavisionProduct.MinBreakingStrength;
    }
    if (data.RopeDimensionId == null) {
      this.analysisRope.RopeDimensionId = null;
      this.analysisRope.RopeDimension = null;
    } else {
      this.analysisRope.RopeDimensionId = data.RopeDimensionId;
      this.analysisRope.RopeDimension = data.RopeDimension;
    }
  }

  private async changeRopePlacement(event) {
    this.analysisRope.RopePlacementId = event.detail.value;

    const data = await this.ropePlacementService.get(this.analysisRope.RopePlacementId);
    this.analysisRope.RopePlacement = data;
    this.checkRopeDirection();
  }
}
