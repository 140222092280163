import { reduceToDecimal } from 'lib/math';
import { AnalysisVariantLoad } from 'models/AnalysisVariant';

export class AnalysisVariantLoads<T> {
  protected originalObject: T;

  /**
   * Updates original object with the provided object.
   * @param newObject the new object to use as reference
   */
  protected updateOriginalObject(newObject: T) {
    if (!newObject) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(newObject));
  }

  public setALSBreakingStrength(totalSafetyFactor: number, target: AnalysisVariantLoad, multiplier = 1) {
    target.MinBreakingStrengthALS = reduceToDecimal(totalSafetyFactor * target.LoadFromAnalysisALS * multiplier, 2);
  }

  public setULSBreakingStrength(totalSafetyFactor: number, target: AnalysisVariantLoad, multiplier = 1) {
    target.MinBreakingStrengthULS = reduceToDecimal(totalSafetyFactor * target.LoadFromAnalysisULS * multiplier, 2);
  }
}
