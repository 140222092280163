import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisVariantLoopService } from 'services/analysis-variant-loop-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { AnalysisVariantLoads } from '../AnalysisVariantLoads';

@containerless
@autoinject
export class AnalysisVariantLoopFormInlineEdit extends AnalysisVariantLoads<Models.AnalysisVariantLoop> {
  @bindable private analysisVariantLoop: Models.AnalysisVariantLoop;
  @bindable private variant: Models.AnalysisVariant;
  @bindable locked = false;

  constructor(
    private analysisVariantLoopService: AnalysisVariantLoopService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService,
    private utility: Utility
  ) {
    super();
  }

  attached() {
    this.updateOriginalObject(this.analysisVariantLoop);
  }

  async updateLoop() {
    try {
      this.setALSBreakingStrength(this.variant.TotalSafetyFactorLoopsALS, this.analysisVariantLoop);
      this.setULSBreakingStrength(this.variant.TotalSafetyFactorLoopsULS, this.analysisVariantLoop);

      if (!this.utility.areEqual(this.analysisVariantLoop, this.originalObject)) {
        const variantLoopToStore = JSON.parse(JSON.stringify(this.analysisVariantLoop));
        delete variantLoopToStore.AnalysisLoop;
        delete variantLoopToStore.AnalysisVariant;
        delete variantLoopToStore.Analysis;

        await this.analysisVariantLoopService.put(variantLoopToStore, this.analysisVariantLoop.Id);

        this.eventAggregator.publish('LoopListReset', 1);
        this.eventAggregator.publish('LoopFormEditClose', 1);
        this.toastService.showSuccess('loop.updated');

        this.updateOriginalObject(this.analysisVariantLoop);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
