import { AnalysisService } from './../../services/analysis-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetService } from 'services/net-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { ToastService } from 'services/toast-service';
import { Role } from 'models/UserModels';

@autoinject
export class AnalysisVariantDetailSigning {
  @bindable private analysisId: number;
  @bindable private analysisVariantId: number;
  private analysisVariant: Models.AnalysisVariant;
  private nextTabIndex: number = null;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];
  analysis: Models.Analysis;
  locked = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private loopService: LoopService,
    private netService: NetService,
    private analysisService: AnalysisService,
    private analysisVariantService: AnalysisVariantService,
    private router: Router,
    private toastService: ToastService
  ) {}

  attached() {
    if (this.analysisVariantId && this.analysisId) {
      this.activate({
        Id: this.analysisVariantId,
        AnalysisId: this.analysisId,
      });
    }
  }

  private activate(params: { Id: number; AnalysisId: number }) {
    this.analysisId = params.AnalysisId;
    this.getAnalysisVariant();
    this.getAnalysis();
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  private getAnalysis() {
    this.analysisService
      .get(this.analysisId)
      .then((res) => {
        this.analysis = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisVariant() {
    this.analysisVariantService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisVariant = res;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public signAnalysisVariant() {
    // check if signed by atlease one, else show error
    if (!this.analysis.SignedDate && !this.analysis.CoSignedDate) {
      return this.toastService.showError('analysis.analysisHasToBeSigned');
    }

    void this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'analysis.ConfirmSignVariantHeader',
          message: 'analysis.ConfirmSignVariantText',
          actions: {
            continue: { enabled: true, t: 'analysis.SignVariant' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.analysisVariantService
            .signAnalysisVariant(this.analysisVariant)
            .then(() => {
              this.toastService.showSuccess('analysis.variantSigned');
            })
            .catch((err) => this.errorService.handleError(err));
        }
      });
  }

  private updateAnalysisVariant() {
    /*     this.analysisVariant.NetShape = null;
        this.analysisDesign.DesignType = null;
        this.analysisDesign.FloaterType = null; */

    this.analysisVariantService
      .put(this.analysisVariant, this.analysisVariant.Id)
      .then(() => {
        //this.updateOriginalObject();
        this.toastService.showSuccess('analysisVariant.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
