import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetService } from 'services/net-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { AnalysisDeadFishSystemService } from 'services/analysis-dea-fish-system-service';
import { ToastService } from 'services/toast-service';
import { Role } from 'models/UserModels';

@autoinject
export class AnalysisVariantDetailDeadfish {
  @bindable private analysisId: number;
  @bindable private analysisVariantId: number;

  private analysisVariant: Models.AnalysisVariant;
  private analysisDeadFishSystem: Models.AnalysisDeadFishSystem;
  private nextTabIndex: number = null;

  protected locked = false;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private loopService: LoopService,
    private netService: NetService,
    private analysisVariantService: AnalysisVariantService,
    private analysisDeadFishSystemService: AnalysisDeadFishSystemService,
    private router: Router,
    private toastService: ToastService
  ) {}

  protected attached() {
    if (this.analysisVariantId && this.analysisId) {
      this.activate({ Id: this.analysisVariantId, AnalysisId: this.analysisId });
    }
  }

  private activate(params) {
    this.analysisId = params.AnalysisId;
    this.getAnalysisVariant();
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  private getAnalysisVariant() {
    this.analysisVariantService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisVariant = res;
        this.locked = res.Locked ? res.Locked : false;
        this.getAnalysisDeadFishSystem(res.AnalysisDeadFishSystemId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisDeadFishSystem(id) {
    this.analysisDeadFishSystemService
      .get(id)
      .then((res) => {
        this.analysisDeadFishSystem = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public updateAnalysisDeadFishSystem() {
    this.analysisVariant.AnalysisDeadFishSystem = null;

    this.analysisDeadFishSystemService
      .put(this.analysisDeadFishSystem, this.analysisDeadFishSystem.Id)
      .then((res) => {
        this.analysisDeadFishSystem = res;
        this.toastService.showSuccess('analysis.deadFishSystemUpdated');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
