import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-templating';
import { LoadVariant } from 'models/AnalysisVariant';

@autoinject
@containerless
export class LoadFactors {
  @bindable type: LoadVariant = 'ALS';
  @bindable materialLocked = false;
  @bindable loadLocked = false;
  @bindable onChange: () => void;

  @bindable loadFactor: number;
  @bindable materialFactor: number;
  @bindable totalSafetyFactor: number;

  @bindable labelRope: string;
  @bindable labelMaterial: string;
}
