import { HistoryDialog } from './../history-dialog/history-dialog';
import { AuthorizeStep } from './../../authorizeStep';
import { Prompt } from 'elements/prompt';
import { DialogController, DialogService } from 'aurelia-dialog';
import { ReportService } from 'services/report-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM, bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { AnalysisService } from 'services/analysis-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { AnalysisDesignService } from './../../services/analysis-design-service';
import { ServiceStatusService } from 'services/service-status-service';
import { ToastService } from 'services/toast-service';
import * as moment from 'moment';
import { Role } from 'models/UserModels';
import { isInRole } from '../../lib/user/userHelper';
import { ApplicationState } from 'lib/state';
import { connectTo } from 'aurelia-store';

@autoinject
@connectTo()
export class AnalysisVariantDetailMain {
  private analysisVariant: Models.AnalysisVariant;
  private analysis: Models.Analysis;
  private analysisDesign: Models.AnalysisDesign;
  private locked: Boolean = false;
  private tabsArray: Array<string>;
  private subscription: Subscription;
  private subscriptionAnalysisVariantUpdated: Subscription;

  @bindable private analysisVariantId: number;
  @bindable private analysisId: number;
  @bindable private isDialog: boolean = true;

  @bindable private pageanalysisVariant;
  @bindable private pageOriginalObject;

  private isAnalysisEngineer: boolean = false;

  private activeIndex: number;
  private orderDetailsRef: any;

  private state: ApplicationState;
  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];
  isAllowedToUpdate = false;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private analysisService: AnalysisService,
    private router: Router,
    private toastService: ToastService,
    private analysisVariantService: AnalysisVariantService,
    private serviceStatusService: ServiceStatusService,
    private deleteDialogService: DeleteDialogService,
    private reportService: ReportService,
    private analysisDesignService: AnalysisDesignService,
    private dialogService: DialogService,
    private dialogController: DialogController
  ) {}

  public canDeactivate() {
    if (!this.isDialog) {
      return true;
    }

    const refArray = [this.orderDetailsRef];

    if (!refArray[this.activeIndex]) {
      return true;
    }

    return refArray[this.activeIndex].viewModel.canDeactivate();
  }

  public async unlockAnalysisVariant() {
    void this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'analysis.ConfirmUnlockVariantHeader',
          message: 'analysis.ConfirmUnlockVariantText',
          actions: {
            continue: { enabled: true, t: 'analysisVariant.unlock' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.analysisVariantService
            .unlockAnalysisVariant(this.analysisVariantId)
            .then(() => {
              this.getAnalysisVariant();
              this.toastService.showSuccess('analysis.unlockedAnalysisVariant');
            })
            .catch((err) => this.errorService.handleError(err));
        }
      });
  }

  private getTabIndex(tabName: string) {
    if (!this.tabsArray) {
      this.setupTabsArray();
    }
    const index = this.tabsArray.indexOf(tabName);

    return this.tabsArray.indexOf(tabName);
  }

  private setupTabsArray() {
    const tabsArray = ['orderDetails'];

    this.tabsArray = tabsArray;
  }

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'analysis-environment',
        moduleId: PLATFORM.moduleName('./analysis-variant-detail-environment'),
        tabindex: 0,
      },
      {
        route: ['deadfish'],
        name: 'analysis-deadfish',
        moduleId: PLATFORM.moduleName('./analysis-variant-detail-deadfish'),
        tabindex: 1,
      },
      {
        route: ['sinker'],
        name: 'analysis-sinker',
        moduleId: PLATFORM.moduleName('./analysis-variant-detail-sinker'),
        tabindex: 2,
      },
      {
        route: ['signing'],
        name: 'analysis-signing',
        moduleId: PLATFORM.moduleName('./analysis-variant-detail-signing'),
        tabindex: 3,
      },
      {
        route: ['breaking-strength'],
        name: 'analysis-breaking-strength',
        moduleId: PLATFORM.moduleName('./analysis-variant-detail-breaking-strength'),
        tabindex: 4,
      },
    ]);
  }

  private async deleteVariant() {
    try {
      await this.analysisVariantService.delete(this.analysisVariantId);
      this.toastService.showSuccess('analysis.deletedVariant');
      this.dialogController.cancel('force');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private attached() {
    if (this.isDialog) {
      this.activate({ Id: this.analysisVariantId, AnalysisId: this.analysisId, IsDialog: this.isDialog });

      this.eventAggregator.subscribe('changeTabIndex', (index) => {
        this.activeIndex = index;
      });

      this.eventAggregator.subscribe('changeTabIndexByName', (name) => {
        this.activeIndex = this.getTabIndex(name);
      });
    }
  }

  private activate(params) {
    this.analysisVariantId = params.Id;
    this.getAnalysisVariant();
    this.getAnalysis(params.AnalysisId);

    this.subscriptionAnalysisVariantUpdated = this.eventAggregator.subscribe('analysisVariantUpdated', (value) => {
      this.getAnalysisVariant();
    });

    this.subscription = this.eventAggregator.subscribe('analysisVariantLocked', (value) => {
      this.getAnalysisVariant();
    });

    if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('AnalysisEngineer') !== -1) {
      this.isAnalysisEngineer = true;
    }

    this.isAllowedToUpdate = isInRole(this.state.roles, this.rolesAllowedToUpdate);
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
    if (this.subscriptionAnalysisVariantUpdated) {
      this.subscriptionAnalysisVariantUpdated.dispose();
    }
  }

  private detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }
    if (this.subscriptionAnalysisVariantUpdated) {
      this.subscriptionAnalysisVariantUpdated.dispose();
    }
  }

  private getAnalysis(id) {
    this.analysisService
      .get(id)
      .then((res) => {
        this.analysis = res;
        this.getAnalysisDesign(res.AnalysisDesignId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisVariant() {
    this.analysisVariantService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisVariant = res;
        this.locked = res.Locked ? res.Locked : false;
        this.eventAggregator.publish('analysisVariantMainHeaderReady');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisDesign(id) {
    this.analysisDesignService
      .get(id + '?$expand=NetShape')
      .then((res) => {
        this.analysisDesign = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private showHistory() {
    if (!this.analysisVariant) {
      return;
    }

    let viewModel = {
      EntityType: 'AnalysisVariant',
      EntityId: this.analysisVariant.Id,
    };

    this.dialogService.open({ viewModel: HistoryDialog, model: viewModel, lock: false, position: (mc, mo) => {} });
  }
}
