import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisVariantNettingService } from 'services/analysis-variant-netting-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { AnalysisVariantLoads } from '../AnalysisVariantLoads';

@autoinject
@containerless
export class AnalysisVariantNettingFormInlineEdit extends AnalysisVariantLoads<Models.AnalysisVariantNetting> {
  @bindable private analysisVariantNetting: Models.AnalysisVariantNetting;
  @bindable private variant: Models.AnalysisVariant;
  @bindable locked = false;

  constructor(
    private analysisVariantNettingService: AnalysisVariantNettingService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private utility: Utility,
    private errorService: ErrorService
  ) {
    super();
  }

  attached() {
    this.updateOriginalObject(this.analysisVariantNetting);
  }

  async updateNetting() {
    try {
      this.setALSBreakingStrength(this.variant.TotalSafetyFactorNettingsALS, this.analysisVariantNetting, 2);
      this.setULSBreakingStrength(this.variant.TotalSafetyFactorNettingsULS, this.analysisVariantNetting, 2);

      if (!this.utility.areEqual(this.analysisVariantNetting, this.originalObject)) {
        const variantNettingToStore = JSON.parse(JSON.stringify(this.analysisVariantNetting));
        delete variantNettingToStore.AnalysisNetting;
        delete variantNettingToStore.AnalysisVariant;
        delete variantNettingToStore.Analysis;

        await this.analysisVariantNettingService.put(variantNettingToStore, this.analysisVariantNetting.Id);
        this.eventAggregator.publish('NettingListReset', 1);
        this.eventAggregator.publish('NettingFormEditClose', 1);
        this.toastService.showSuccess('netting.updated');
        this.updateOriginalObject(this.analysisVariantNetting);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
