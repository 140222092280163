import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetService } from 'services/net-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { ToastService } from 'services/toast-service';
import { Role } from 'models/UserModels';

@autoinject
export class AnalysisVariantDetailEnvironment {
  @bindable private analysisId: number;
  @bindable private analysisVariantId: number;

  private analysisVariant: Models.AnalysisVariant;
  private nextTabIndex: number = null;
  private is2021Standard = false;

  locked = false;
  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private loopService: LoopService,
    private netService: NetService,
    private analysisVariantService: AnalysisVariantService,
    private router: Router,
    private toastService: ToastService
  ) {}

  attached() {
    if (this.analysisVariantId && this.analysisId) {
      this.activate({ Id: this.analysisVariantId, AnalysisId: this.analysisId });
    }
  }

  private activate(params: { AnalysisId: number; Id: number }) {
    this.analysisId = params.AnalysisId;
    this.getAnalysisVariant();
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  private getAnalysisVariant() {
    this.analysisVariantService
      .get(this.analysisVariantId + '?$expand=Analysis($expand=Netstandard)')
      .then((res) => {
        this.analysisVariant = res;
        this.is2021Standard = this.analysisVariant?.Analysis?.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  updateAnalysisVariant() {
    /*     this.analysisVariant.NetShape = null;
        this.analysisDesign.DesignType = null;
        this.analysisDesign.FloaterType = null; */

    this.analysisVariantService
      .put(this.analysisVariant, this.analysisVariant.Id)
      .then(() => {
        this.toastService.showSuccess('analysisVariant.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
