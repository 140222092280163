import { Utility } from 'utility';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { AnalysisVariantRopeService } from 'services/analysis-variant-rope-service';
import { AnalysisVariantLoopService } from 'services/analysis-variant-loop-service';
import { AnalysisVariantNettingService } from 'services/analysis-variant-netting-service';
import { ToastService } from 'services/toast-service';
import { AnalysisService } from 'services/analysis-service';
import { Role } from 'models/UserModels';

@autoinject
export class AnalysisVariantDetailBreakingStrength {
  @bindable private analysisId: number;
  @bindable private analysisVariantId: number;
  private analysis: Models.Analysis;
  private nextTabIndex: number = null;

  private analysisVariant: Models.AnalysisVariant;
  private originalObject: Models.AnalysisVariant;

  analysisVariantRopes: Array<Models.AnalysisVariantRope>;
  analysisVariantNettings: Array<Models.AnalysisVariantNetting>;
  analysisVariantLoops: Array<Models.AnalysisVariantLoop>;

  locked = false;
  is2021Standard = false;
  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private analysisService: AnalysisService,
    private analysisVariantService: AnalysisVariantService,
    private analysisVariantRopeService: AnalysisVariantRopeService,
    private analysisVariantLoopService: AnalysisVariantLoopService,
    private analysisVariantNettingService: AnalysisVariantNettingService,
    private toastService: ToastService,
    private utility: Utility
  ) { }

  attached() {
    if (this.analysisVariantId && this.analysisId) {
      this.activate({
        Id: this.analysisVariantId,
        AnalysisId: this.analysisId,
      });
    }
  }

  private activate(params: { Id: number; AnalysisId: number }) {
    this.analysisId = params.AnalysisId;
    this.getAllData();
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private getAllData() {
    void this.getAnalysis();
    this.getAnalysisVariant();
    this.getAnalysisVariantRopes();
    this.getAnalysisVariantNettings();
    this.getAnalysisVariantLoops();
  }

  private getAnalysisVariant() {
    this.analysisVariantService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisVariant = res;
        this.locked = res.Locked ? res.Locked : false;
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateOriginalObject() {
    if (!this.analysisVariant) {
      return;
    }

    this.originalObject = JSON.parse(JSON.stringify(this.analysisVariant));
  }

  async saveUpdatedLoadAndMaterialFactors() {
    try {
      if (!this.utility.areEqual(this.analysisVariant, this.originalObject)) {
        await this.analysisVariantService.put(this.analysisVariant, this.analysisVariant.Id);
        this.toastService.showSuccess('analysisVariant.LoadFactorAndMaterialsUpdated');
        this.getAllData();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getAnalysis() {
    try {
      this.analysis = await this.analysisService.get(this.analysisId + '?$expand=NetStandard');
      this.is2021Standard = this.analysis.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getAnalysisVariantRopes() {
    this.analysisVariantRopeService
      .getAll(`?$filter=AnalysisVariantId eq ${this.analysisVariantId}`)
      .then((res) => {
        this.analysisVariantRopes = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisVariantNettings() {
    this.analysisVariantNettingService
      .getAll(`?$filter=AnalysisVariantId eq ${this.analysisVariantId}`)
      .then((res) => {
        this.analysisVariantNettings = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisVariantLoops() {
    this.analysisVariantLoopService
      .getAll(`?$filter=AnalysisVariantId eq ${this.analysisVariantId}`)
      .then((res) => {
        this.analysisVariantLoops = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }
}
