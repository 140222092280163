import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeHardnessService } from 'services/rope-hardness-service';
import { RopePlacementService } from 'services/rope-placement-service';
import { AnalysisRopeService } from 'services/analysis-rope-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';
import { MaterialService } from 'services/material-service';
import { RopeDirection } from 'models/RopeDirection';

@containerless
@autoinject
export class AnalysisRopeFormInlineNew {
  @bindable private analysisId: number;
  @bindable private minimumBreakingStrength;

  private analysisRope: Models.AnalysisRope = new Models.AnalysisRope();
  private currentRopeType: Models.AnalysisRope;
  private directionHorizontal = false;
  private actualBreakingStrengthMissing = false;

  constructor(
    private analysisRopeService: AnalysisRopeService,
    private ropeTypeService: RopeTypeService,
    private ropePlacementService: RopePlacementService,
    private materialService: MaterialService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private ropeHardnessService: RopeHardnessService,
    private ropeDimensionService: RopeDimensionService
  ) {}

  private async createRope() {
    this.analysisRope.AnalysisId = this.analysisId;
    this.analysisRope.RopeDimension = null;

    try {
      await this.analysisRopeService.post(this.analysisRope);
      this.eventAggregator.publish('ropeListReset', 1);
      this.eventAggregator.publish('ropeFormNewClose', 1);
      this.toastService.showSuccess('rope.created');
      this.analysisRope = new Models.AnalysisRope();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private cancel() {
    this.eventAggregator.publish('ropeFormNewClose', 1);
  }

  private async changeRopeType(event) {
    this.analysisRope.RopeTypeId = event.detail.value;

    const data = await this.ropeTypeService.get(
      this.analysisRope.RopeTypeId + '?$expand=RopeDimension,NavisionProduct'
    );

    if (data.NavisionProductId == null) {
      this.analysisRope.MinBreakingStrength = null;
    } else {
      this.analysisRope.MinBreakingStrength = data.NavisionProduct.MinBreakingStrength;
    }
    if (data.RopeDimensionId == null) {
      this.analysisRope.RopeDimensionId = null;
      this.analysisRope.RopeDimension = null;
    } else {
      this.analysisRope.RopeDimensionId = data.RopeDimensionId;
      this.analysisRope.RopeDimension = data.RopeDimension;
    }
  }

  private async changeRopePlacement(event) {
    this.analysisRope.RopePlacementId = event.detail.value;

    const data = await this.ropePlacementService.get(this.analysisRope.RopePlacementId);

    if (data.Direction == RopeDirection.Horizontal) {
      this.directionHorizontal = true;
    } else {
      this.directionHorizontal = false;
      this.analysisRope.Depth = null;
    }
  }
}
