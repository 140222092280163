import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetService } from 'services/net-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { AnalysisSingleSinkerService } from 'services/analysis-single-sinker-service';
import { AnalysisSpagetthiSinkerService } from 'services/analysis-spagetthi-sinker-service';
import { AnalysisRingSinkerService } from 'services/analysis-ring-sinker-service';
import { RingSinkerNetConnectionTypeService } from 'services/ring-sinker-net-connection-type-service';
import { RingSinkerFloaterConnectionTypeService } from 'services/ring-sinker-floater-connection-type-service';
import { ToastService } from 'services/toast-service';
import { Role } from 'models/UserModels';
import { AnalysisSinkerMaterialService } from 'services/analysis-sinker-material-service';
import { AnalysisSinkerMaterial } from 'models/AnalysisSinkerMaterial';

@autoinject
export class AnalysisVariantDetailSinker {
  @bindable private analysisId: number;
  @bindable private analysisVariantId: number;

  private analysisVariant: Models.AnalysisVariant;
  private analysisSingleSinker: Models.AnalysisSingleSinker;
  private analysisSpagetthiSinker: Models.AnalysisSpagetthiSinker;
  private analysisRingSinker: Models.AnalysisRingSinker;
  private nextTabIndex: number = null;
  private sinkerMaterialsOptions: AnalysisSinkerMaterial[]
  private defaultAnlyisSinkerMaterialId: number;

  protected locked = false;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private analysisVariantService: AnalysisVariantService,
    private analysisSingleSinkerService: AnalysisSingleSinkerService,
    private analysisSpagetthiSinkerService: AnalysisSpagetthiSinkerService,
    private analysisRingSinkerService: AnalysisRingSinkerService,
    private analysisSinkerMaterialService : AnalysisSinkerMaterialService,
    private toastService: ToastService,
    protected dialogService: DialogService,
    protected loopService: LoopService,
    protected netService: NetService,
    protected ringSinkerNetConnectionTypeService: RingSinkerNetConnectionTypeService,
    protected ringSinkerFloaterConnectionTypeService: RingSinkerFloaterConnectionTypeService,
    protected router: Router
  ) {}

  protected attached() {
    if (this.analysisVariantId && this.analysisId) {
      this.activate({
        Id: this.analysisVariantId,
        AnalysisId: this.analysisId,
      });
    }
    void this.getAnalysisSinkerMaterial();
  }

  private async getAnalysisSinkerMaterial () {
    this.sinkerMaterialsOptions = await this.analysisSinkerMaterialService.getAll();

    this.defaultAnlyisSinkerMaterialId = this.sinkerMaterialsOptions.find(m => m.Name === "Stål")?.Id;
  }

  private activate(params: { AnalysisId: number; Id: number }) {
    this.analysisId = params.AnalysisId;
    this.getAnalysisVariant();
    this.getAnalysisSingleSinker();
    this.getAnalysisSpagetthiSinker();
    this.getAnalysisRingSinker();
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  protected canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  private getAnalysisVariant() {
    this.analysisVariantService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisVariant = res;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisSingleSinker() {
    this.analysisSingleSinkerService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisSingleSinker = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisSpagetthiSinker() {
    this.analysisSpagetthiSinkerService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisSpagetthiSinker = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAnalysisRingSinker() {
    this.analysisRingSinkerService
      .get(this.analysisVariantId)
      .then((res) => {
        this.analysisRingSinker = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected updateAnalysisSingleSinker() {
    Promise.all([
      this.analysisVariantService.put(this.analysisVariant, this.analysisVariant.Id),
      this.analysisSingleSinkerService.put(this.analysisSingleSinker, this.analysisSingleSinker.Id),
    ])
      .then(() => {
        this.toastService.showSuccess('analysisSingleSinker.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected updateAnalysisSpagetthiSinker() {
    Promise.all([
      this.analysisVariantService.put(this.analysisVariant, this.analysisVariant.Id),
      this.analysisSpagetthiSinkerService.put(this.analysisSpagetthiSinker, this.analysisSpagetthiSinker.Id),
    ])
      .then(() => {
        this.toastService.showSuccess('analysisSpagetthiSinker.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected updateAnalysisRingSinker() {
    this.analysisRingSinker.RingSinkerNetConnectionType = null;
    this.analysisRingSinker.RingSinkerFloaterConnectionType = null;

    Promise.all([
      this.analysisVariantService.put(this.analysisVariant, this.analysisVariant.Id),
      this.analysisRingSinkerService.put(this.analysisRingSinker, this.analysisRingSinker.Id),
    ])
      .then(() => {
        this.toastService.showSuccess('analysisRingSinker.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected updateAnalysisVariant() {
    /*     this.analysisVariant.NetShape = null;
        this.analysisDesign.DesignType = null;
        this.analysisDesign.FloaterType = null; */

    this.analysisVariantService
      .put(this.analysisVariant, this.analysisVariant.Id)
      .then(() => {
        //this.updateOriginalObject();
        this.toastService.showSuccess('analysisVariant.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
