import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisVariantRopeService } from 'services/analysis-variant-rope-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { AnalysisVariantLoads } from '../AnalysisVariantLoads';

@autoinject
@containerless
export class AnalysisVariantRopeFormInlineEdit extends AnalysisVariantLoads<Models.AnalysisVariantRope> {
  @bindable private analysisVariantRope: Models.AnalysisVariantRope;
  @bindable private variant: Models.AnalysisVariant;
  @bindable locked = false;

  constructor(
    private analysisVariantRopeService: AnalysisVariantRopeService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService,
    private utility: Utility
  ) {
    super();
  }

  attached() {
    this.updateOriginalObject(this.analysisVariantRope);
  }

  async updateRope() {
    try {
      this.setALSBreakingStrength(this.variant.TotalSafetyFactorRopesALS, this.analysisVariantRope);
      this.setULSBreakingStrength(this.variant.TotalSafetyFactorRopesULS, this.analysisVariantRope);

      if (!this.utility.areEqual(this.analysisVariantRope, this.originalObject)) {
        const variantRopeToStore = JSON.parse(JSON.stringify(this.analysisVariantRope));
        delete variantRopeToStore.AnalysisRope;
        delete variantRopeToStore.AnalysisVariant;
        delete variantRopeToStore.Analysis;

        await this.analysisVariantRopeService.put(variantRopeToStore, this.analysisVariantRope.Id);

        this.eventAggregator.publish('ropeListReset', 1);
        this.eventAggregator.publish('ropeFormEditClose', 1);
        this.toastService.showSuccess('rope.updated');

        this.updateOriginalObject(this.analysisVariantRope);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
