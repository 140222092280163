import { autoinject, bindable } from 'aurelia-framework';
import { DialogCloseResult, DialogController, DialogService } from 'aurelia-dialog';

import { AnalysisVariantService } from 'services/analysis-variant-service';
import { ErrorService } from 'services/error-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Utility } from 'utility';

@autoinject
export class AnalysisVariantDialog {
    @bindable() private variant;
    @bindable() private originalObject;
    
    private variantId;
    private analysisId;
    private canDeactivateExternal;
    private dialog: boolean = true;

    private variantDetailMain;
    private ready: boolean = true;

    constructor(
      private analysisVariantService: AnalysisVariantService,
      private dialogController: DialogController,
      private dialogService: DialogService,
      private errorService: ErrorService,
      private eventAggregator: EventAggregator,
      private utility: Utility,
    ) {}

    private activate(params) {
      this.variantId = params.Id;
      this.analysisId = params.AnalysisId;

      this.eventAggregator.subscribe('closeVariantDetailsDialog', (data) => {
        this.dialogController.cancel('force');
      });

      this.eventAggregator.subscribe('variantMainHeaderReady', (data) => {
        this.ready = true;
      });
    }

    private canDeactivate(dialogResult: DialogCloseResult) {
      if (dialogResult.output === 'force') {
        return true;
      } else {
        return this.variantDetailMain.viewModel.canDeactivate();
      }
    }
}
